import styled from 'styled-components'
import { FunctionComponent } from 'react'
import {
  useLoadRFPDocumentSummaries,
  useRFPDocumentSummaries,
} from '../state/modules/rfpDocumentSummaries'
import { useCallOnceOnIDTokenGranted } from '../state/modules/common'
import { Accordion, Container } from 'react-bootstrap'
import { CustomerRFPSection } from '../components/DocumentExchange/CustomerRFPSection'

const StyledContainer = styled(Container)`
  margin-top: 2rem;
`

export const DocumentExchange: FunctionComponent = () => {
  const loadRFPDocumentSummaries = useLoadRFPDocumentSummaries()
  useCallOnceOnIDTokenGranted(loadRFPDocumentSummaries)
  const { payload: rfpDocumentSummaries } = useRFPDocumentSummaries()

  return (
    <StyledContainer>
      {rfpDocumentSummaries && (
        <Accordion>
          {rfpDocumentSummaries.map((rfpDocumentSummary) => (
            <CustomerRFPSection
              key={rfpDocumentSummary.customerRFPId}
              rfpDocumentSummary={rfpDocumentSummary}
            />
          ))}
        </Accordion>
      )}
    </StyledContainer>
  )
}
