import styled from 'styled-components'
import { FunctionComponent } from 'react'
import { SiteRFPDocumentSummary } from '@black-bear-energy/black-bear-energy-common'

interface SiteRFPSectionProps {
  siteRFPDocumentSummary: SiteRFPDocumentSummary
}

const StyledSiteRFPSection = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.75rem 1.25rem;
`

const StyledMetricsContainer = styled('div')`
  display: flex;
  flex-direction: column;
  text-align: right;
`

export const SiteRFPSection: FunctionComponent<SiteRFPSectionProps> = ({
  siteRFPDocumentSummary,
}: SiteRFPSectionProps) => {
  return (
    <StyledSiteRFPSection>
      <div>{siteRFPDocumentSummary.name}</div>
      <StyledMetricsContainer>
        <div>
          {siteRFPDocumentSummary.uploadedCounts.required} out of{' '}
          {siteRFPDocumentSummary.totalCounts.required} required
        </div>
        <div>
          {siteRFPDocumentSummary.uploadedCounts.reviewed} out of{' '}
          {siteRFPDocumentSummary.uploadedCounts.total} reviewed
        </div>
      </StyledMetricsContainer>
    </StyledSiteRFPSection>
  )
}
